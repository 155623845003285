"use client";

import { type HTMLAttributes, useState } from "react";

import { cn } from "../libs/utils";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  className?: string;
  labelClassName?: string;
  name?: string;
  label?: string;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  errorMessage?: string;
  errorClassName?: HTMLAttributes<HTMLDivElement>["className"];
  isError?: boolean;
  onSubmit?: () => void;
}

const Input: React.FC<InputProps> = ({
  className = "",
  label,
  containerClassName = "",
  labelClassName = "",
  name,
  preIcon,
  postIcon,
  onSubmit,
  errorMessage,
  errorClassName,
  isError = false,
  ...inputProps
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <div
        className={cn(
          "rounded-12 relative flex rounded-md border bg-white/5 p-2 outline outline-1 outline-zinc-200 transition-all duration-300 xl:hover:border-gray-400",
          containerClassName,
          {
            "border-white/50": isFocused,
            "border-white/20": !isFocused,
            "!border-red-500": !!errorMessage || isError,
          }
        )}
      >
        {!!preIcon && (
          <div className="absolute left-4 top-0 flex h-full items-center justify-center self-center">
            {preIcon}
          </div>
        )}

        <div
          className={cn("w-full", {
            "pl-8": !!preIcon,
            "pr-8": !!postIcon,
          })}
        >
          {!!label && (
            <div
              className={cn(
                "mb-1 text-xs font-light opacity-75",
                labelClassName
              )}
            >
              {label}
            </div>
          )}
          <input
            className={cn(
              "text-20 w-full bg-transparent outline-none",
              className
            )}
            name={name}
            {...inputProps}
            onFocus={(e) => {
              setIsFocused(true);
              inputProps?.onFocus?.(e);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              inputProps?.onBlur?.(e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit?.();
              }
            }}
          />
        </div>

        {!!postIcon && (
          <div className="absolute right-4 top-0 flex h-full items-center justify-center self-center">
            {postIcon}
          </div>
        )}
      </div>
      {!!errorMessage && (
        <div className={cn("mt-2 text-xs text-red-500", errorClassName)}>
          {errorMessage}
        </div>
      )}
    </>
  );
};

export { Input };
